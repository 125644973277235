import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "customError",
    "customErrorText",
    "firstName",
    "form",
    "isSubmittingSpinner",
    "lastName",
    "submit",
  ];

  static values = {
    submitBehavior: String, // "iframe-emit" or "post"
  };

  connect() {
    this.asyncSubmitHandlers = [];
    if (this.submitBehaviorValue === "iframe-emit") {
      this.formTarget.addEventListener("submit", this.#emitOnFormSubmit.bind(this));
    }
  }

  showSpinner() {
    this.isSubmittingSpinnerTarget.hidden = false;
  }

  hideSpinner() {
    this.isSubmittingSpinnerTarget.hidden = true;
  }

  /*
   * functions used by other controllers
   */
  firstName() {
    return this.lastNameTarget?.value;
  }

  lastName() {
    return this.lastNameTarget?.value;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }

  showSubmit() {
    this.submitTarget.hidden = false;
  }

  // This is an hacky workaround for the combination of a few things:
  //   - The need to interrupt form submission to perform async operations (e.g. tokenization)
  //   - If you set an "async" function as a form-submit event listener, the browser doesn't "await" the function
  //   - Hotwire wants to listen to normal form submission events, so we need to use `form.requestSubmit()` instead of `form.submit()`
  // Those things combined mean we do the following when we need async form handling:
  //   1. Set up a form-submit handler that stops normal form submission and triggers async handling
  //   2. After async handling succeeds, re-trigger normal form submission (via `requestSubmit`) but with a special variable set to
  //     skip async handling
  //
  // `func` should be an async function that runs true/false for success/failure.
  addAsyncSubmitInterrupt(func) {
    this.asyncSubmitHandlers.push(func);
    if (this.asyncSubmitHandlers.length === 1) {
      this.formTarget.addEventListener("submit", this.runAsyncSubmitInterrupt, true);
    }
  }

  removeAsyncSubmitInterrupt(func) {
    const index = this.asyncSubmitHandlers.indexOf(func);
    if (index >= 0) {
      this.asyncSubmitHandlers.splice(index, 1);

      if (!this.asyncSubmitHandlers.length) {
        this.formTarget.removeEventListener("submit", this.runAsyncSubmitInterrupt, true);
      }
    }
  }

  runAsyncSubmitInterrupt = async (event) => {
    if (this.bypassSubmitInterrupt) {
      return;
    }
    event.stopImmediatePropagation();
    event.preventDefault();
    for (const handler of this.asyncSubmitHandlers) {
      const success = await handler();

      if (!success) {
        this.enableSubmit();
        this.hideSpinner();
        return;
      }
    }

    this.bypassSubmitInterrupt = true;
    try {
      this.formRequestSubmit();
    } finally {
      this.bypassSubmitInterrupt = false;
    }
  };

  onFormSubmit(func) {
    this.formTarget.addEventListener("submit", func);
  }

  formRequestSubmit() {
    this.formTarget.requestSubmit();
  }

  setCustomError(message) {
    this.customErrorTarget.hidden = !message;
    this.customErrorTextTarget.innerText = message || "";
  }
  /*
   * end functions used by other controllers
   */

  #emitOnFormSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    const { target } = event;

    const windowOrigin = window.top.location.origin;
    if (!windowOrigin.match(/(rinsed.com|rinsed.co)(:\d+)?$/)) {
      return;
    }

    window.top.postMessage(
      {
        type: "rinsed:payment_update_form_submit",
        card_connect_token: this.#formValue(target, "payment_method[card_connect_token]"),
        card_connect_expiry: this.#formValue(target, "payment_method[card_connect_expiry]"),
      },
      windowOrigin
    );
  }

  #formValue(form, fieldName) {
    return form.querySelector(`input[name='${fieldName}']`).value;
  }
}
