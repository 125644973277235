import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["copiedIndicator"];

  copy(event) {
    event.preventDefault();
    navigator.clipboard.writeText(event.currentTarget.dataset.textToCopy);

    this.#showCopiedIndicator();
  }

  disconnect() {
    this.#clearCopiedIndicatorTimeout();
  }

  #showCopiedIndicator() {
    this.#clearCopiedIndicatorTimeout();

    if (!this.hasCopiedIndicatorTarget) {
      return;
    }

    this.copiedIndicatorTarget.classList.remove("hidden");
    this.hideIndicatorTimeout = setTimeout(() => {
      this.copiedIndicatorTarget.classList.add("hidden");
    }, 3000);
  }

  #clearCopiedIndicatorTimeout() {
    if (this.hideIndicatorTimeout) {
      clearTimeout(this.hideIndicatorTimeout);
      this.hideIndicatorTimeout = null;
    }
  }
}
