import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  static values = { message: String };

  confirmCancel(event) {
    // temporarily use a confirm dialog until we have a modal design for this situation
    if (!confirm(this.messageValue)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  updateFleetProgramSection(event) {
    event.preventDefault();

    const selected_id = document.querySelector("select#fleet_plan_group_program_id").value;
    const url =
      "/admin/fleet_plans/fleet_program_form_section" + `?selected_group_program_id=${selected_id}`;
    get(url, { responseKind: "turbo-stream" });
  }
}
