import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["memberCost", "retailCost", "pointCost"];
  static values = { memberPoints: Number, retailPoints: Number };

  connect() {
    this.currencyFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });

    this.input();
  }

  input() {
    const value = this.pointCostTarget.value;

    if (this.memberPointsValue) {
      this.memberCostTarget.innerText = this.#formatCurrency(value / this.memberPointsValue);
    }
    if (this.retailPointsValue) {
      this.retailCostTarget.innerText = this.#formatCurrency(value / this.retailPointsValue);
    }
  }

  #formatCurrency(number) {
    return this.currencyFormatter.format(number);
  }
}
