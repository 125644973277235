import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["promotionType", "dayOfWeek", "dayOfYear"];

  connect() {
    this.change();
  }

  change() {
    const promotionType = this.promotionTypeTarget.value;
    if (promotionType === "day_of_week") {
      this.dayOfWeekTarget.classList.remove("hidden");
      this.dayOfWeekTarget.disabled = false;

      this.dayOfYearTarget.classList.add("hidden");
      this.dayOfYearTarget.disabled = true;
    } else if (promotionType === "day_of_year") {
      this.dayOfYearTarget.classList.remove("hidden");
      this.dayOfYearTarget.disabled = false;

      this.dayOfWeekTarget.classList.add("hidden");
      this.dayOfWeekTarget.disabled = true;
    }
  }
}
