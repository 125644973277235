import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "buttonContainer"];

  showReward({ target }) {
    target.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
    this.buttonContainerTarget.classList.remove("rounded-lg");
    this.buttonContainerTarget.classList.add("rounded-t-lg");

    this.element.querySelector("#loyalty_program_redemption_rule_name").required = true;
    this.element.querySelector("#loyalty_program_redemption_rule_point_cost").required = true;
  }
}
