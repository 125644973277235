import { Controller } from "@hotwired/stimulus";

const ENABLED_TEXT_CLASS = "text-brand-700";
const DISABLED_TEXT_CLASS = "text-gray-400";

const ENABLED_BORDER_CLASS = "border-brand-700";
const DISABLED_BORDER_CLASS = "border-gray-500";

const ENABLED_BACKGROUND_CLASS = "before:!bg-brand-700";
const DISABLED_BACKGROUND_CLASS = "before:!bg-gray-300";

const MAX_POINT_DIGITS = 3;

export default class extends Controller {
  static targets = ["amount", "input", "toggle", "toggleLabel"];

  connect() {
    this.toggle();
  }

  toggle() {
    if (this.toggleTarget.checked) {
      this.element.classList.add(ENABLED_BORDER_CLASS);
      this.element.classList.remove(DISABLED_BORDER_CLASS);

      this.inputTarget.classList.add(ENABLED_BORDER_CLASS);
      this.inputTarget.classList.remove(DISABLED_BORDER_CLASS);
      this.inputTarget.disabled = false;

      this.toggleLabelTarget.classList.add(ENABLED_BACKGROUND_CLASS);
      this.toggleLabelTarget.classList.remove(DISABLED_BACKGROUND_CLASS);

      this.amountTarget.classList.add(ENABLED_TEXT_CLASS);
      this.amountTarget.classList.remove(DISABLED_TEXT_CLASS);
    } else {
      this.element.classList.add(DISABLED_BORDER_CLASS);
      this.element.classList.remove(ENABLED_BORDER_CLASS);

      this.inputTarget.classList.add(DISABLED_BORDER_CLASS);
      this.inputTarget.classList.remove(ENABLED_BORDER_CLASS);
      this.inputTarget.disabled = true;

      this.toggleLabelTarget.classList.add(DISABLED_BACKGROUND_CLASS);
      this.toggleLabelTarget.classList.remove(ENABLED_BACKGROUND_CLASS);

      this.amountTarget.classList.add(DISABLED_TEXT_CLASS);
      this.amountTarget.classList.remove(ENABLED_TEXT_CLASS);
    }
  }

  input() {
    // Numbers only, up to 3 digits. We don't use a number input type to avoid
    // the arrows that the browser renders.
    this.inputTarget.value = this.inputTarget.value
      .replace(/\D/g, "")
      .substring(0, MAX_POINT_DIGITS);
  }
}
