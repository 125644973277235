import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    // Name of field to watch (e.g. "profile[email]")
    fieldName: String,
    // Name for the hidden field that will be added (e.g. "profile[current_password]")
    confirmationFieldName: String,
    // Message to show in the modal
    message: String,
  };

  connect() {
    // Find field to watch
    const input = document.querySelector(`input[name="${this.fieldNameValue}"]`);
    this.form = input?.closest("form");
    if (!this.form) return;

    this.field = input;
    this.initialValue = this.field.value;

    this.boundValidateChange = this.validateChange.bind(this);
    this.form.addEventListener("submit", this.boundValidateChange);
  }

  validateChange(event) {
    if (this.field.value !== this.initialValue) {
      event.preventDefault();
      this.showModal();
    }
  }

  submitModal(event) {
    event.preventDefault();

    // Get password from modal
    const confirmationValue = this.modal.querySelector("input").value;

    // Remove conflicting input from form
    this.form.querySelector(`input[name="${this.confirmationFieldNameValue}"]`)?.remove();

    // Create new input with confirmation value and add to form
    const hiddenInput = document.createElement("input");
    hiddenInput.type = "hidden";
    hiddenInput.name = this.confirmationFieldNameValue;
    hiddenInput.value = confirmationValue;

    this.form.appendChild(hiddenInput);
    this.hideModal();

    this.form.removeEventListener("submit", this.boundValidateChange);
    this.form.submit();
  }

  closeModal() {
    // Reset to initial value
    this.field.value = this.initialValue;

    // Reset input states
    const formElements = this.form.querySelectorAll("button, input, select, textarea");
    formElements.forEach((element) => {
      element.disabled = false;
    });

    this.form.querySelector(`input[name="${this.confirmationFieldNameValue}"]`)?.remove();

    this.hideModal();
  }

  showModal() {
    this.element.insertAdjacentHTML("beforeend", this.modalTemplate());
    this.modal = this.element.lastElementChild;
  }

  hideModal() {
    this.modal?.remove();
  }

  modalTemplate() {
    return `
      <div class="modal is-active">
        <div class="modal-background" data-action="click->admin--confirm-current-password#closeModal"></div>
        <div class="modal-card">
          <form data-action="admin--confirm-current-password#submitModal:prevent">
            <header class="modal-card-head">
              <p class="modal-card-title">🔒 Confirm password</p>
            </header>

            <section class="modal-card-body">
                <div class="field">
                  <label class="label">
                    ${this.messageValue}
                  </label>
                  <input type="password"
                    class="input"
                    name="${this.confirmationFieldNameValue}"
                    autofocus
                    placeholder="Current password"
                    required
                  >
                </div>

            </section>
            <footer class="modal-card-foot">
              <div class="buttons">
                <button class="button is-primary" type="submit">Confirm</button>
                <button
                  class="button is-text"
                  aria-label="close"
                  data-action="admin--confirm-current-password#closeModal:prevent">
                  Cancel
                </button>
              </div>
            </footer>
          </form>
        </div>
      </div>
    `;
  }

  disconnect() {
    this.form?.removeEventListener("submit", this.boundValidateChange);
    this.hideModal();
  }
}
