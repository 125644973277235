// Init of DD logger is done once from in datadog.js.erb which is included in application.js
// Falls back to console if window.DD_LOGS.logger is not defined (prior to load of sdk)
const logger = window?.DD_LOGS?.logger || {
  log: console.log,
  warn: console.warn,
  error: console.error,
  info: console.info,
  debug: console.debug,
};

export default logger;
